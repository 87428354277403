










































































import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'

import { SubscriptionApi } from '@/api'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormInput, { InputField, FormInputInstance } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import { isArray } from 'lodash'
import { User } from '@/types'
import { Watch } from 'vue-property-decorator'

interface InputFields {
  suspendFrom: InputField;
  suspendTo: InputField;
  declarationFile: InputField;
  explanation: InputField;
}

const ErrorStrings = ['NOERROR', 'COULDNOTLOADSUBSCRIPTION', 'SUBSCRIPTIONALREADYCANCELED'] as const
type ErrorTypes = typeof ErrorStrings[number]

@Component({
  components: {
    Content,
    Toolbar,
    FormInput,
    FormButton
  }
})
export default class SubscriptionSuspend extends mixins(State) {
  inputFields: InputFields = {
    suspendFrom: {
      name: 'suspendFrom',
      value: '',
      required: true,
      type: 'date',
      placeholder: 'Graag opschorten vanaf',
      errors: [],
      min: this.minFromDate
    },
    suspendTo: {
      name: 'suspendTo',
      value: '',
      required: true,
      type: 'date',
      placeholder: 'Graag opschorten tot',
      errors: [],
      min: this.minToDate,
      disabled: true
    },
    declarationFile: {
      name: 'declarationFile',
      value: '',
      required: true,
      type: 'file',
      placeholder: 'Upload medische verklaring of zwangerschapsverklaring',
      errors: [],
      customErrorMessage: 'Vergeet niet je bestand te uploaden',
      info: 'Je kunt de volgende bestandstypen uploaden: .jpg, .png, .pdf',
      infoColor: 'black',
      inputExplanationParagraph: 'Voorbeelden medische verklaring: verwijsbrief, opname afspraak, verklaring fysiotherapeut. Vertrouwelijke informatie mag je onleesbaar maken.'
    },
    explanation: {
      name: 'explanation',
      value: '',
      required: true,
      type: 'textarea',
      placeholder: 'Toelichting',
      errors: []
    }
  }

  errorState: ErrorTypes = 'NOERROR'

  get errorStatem (): ErrorTypes {
    return this.errorState
  }

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get cancellationScheduled (): boolean {
    return this.currentUser.subscriptionDetails?.cancellationProcessDate !== null
  }

  get subscription () {
    return this.currentUser.subscriptionDetails
  }

  get minFromDate () {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`
  }

  get minToDate () {
    if (this.inputFields?.suspendFrom.value) {
      const date = new Date(this.inputFields.suspendFrom.value)
      date.setMonth(date.getMonth() + 1)
      return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`
    }
    return this.minFromDate
  }

  @Watch('inputFields.suspendFrom.value')
  onSuspendFromDateChanged () {
    if (this.inputFields) {
      if (this.inputFields.suspendFrom.value !== '') {
        this.inputFields.suspendTo.value = this.minToDate
        this.inputFields.suspendTo.disabled = false
      }
      this.inputFields.suspendTo.min = this.minToDate
    }
  }

  public async suspendSubscription () {
    this.clearErrors()
    if (!this.validate() || !this.inputFields) {
      return
    }

    const api = new SubscriptionApi()
    try {
      this.state = this.states.LOADING
      this.clearErrors()
      await api.subscriptionSuspend({
        subscriptionId: this.currentUser.subscription?.subscriptionId || 0,
        suspendFrom: this.inputFields.suspendFrom.value,
        suspendTo: this.inputFields.suspendTo.value,
        explanation: this.inputFields.explanation.value,
        uploadId: this.inputFields.declarationFile.value
      }
      )

      this.state = this.states.COMPLETE
    } catch (error) {
      this.state = this.states.ERROR

      const errorsWithoutKeys = Object.entries(error.response.data.errors).filter(it => it[0] === '').map(it => (it[1] as string).toString().toUpperCase())
      if (errorsWithoutKeys.includes(ErrorStrings[1] as string)) {
        this.errorState = 'COULDNOTLOADSUBSCRIPTION'
      } else if (errorsWithoutKeys.includes(ErrorStrings[2] as string)) {
        this.errorState = 'SUBSCRIPTIONALREADYCANCELED'
      } else {
        this.setErrors(error.response.data.errors)
      }
    }
  }

  public setErrors (errors: []) {
    if (this.inputFields) {
      Object.entries(errors).forEach(([errorKey, errorValue]) => {
        Object.entries(this.inputFields).forEach(([fieldKey, fieldValue]) => {
          if (errorKey.toLowerCase() === fieldKey.toLowerCase()) {
            fieldValue.errors = errorValue
          }
        })
      })
    }
  }

  public clearErrors () {
    if (this.inputFields) {
      Object.values(this.inputFields).forEach((fieldValue: InputField) => {
        fieldValue.errors = []
      })
    }
  }

  public validate () {
    let valid = true
    if (this.inputFields) {
      Object.values(this.inputFields).forEach((fieldValue: InputField) => {
        const field = (this.$refs[fieldValue.name] as FormInputInstance)
        if (isArray(field)) {
          valid = field.every((inputField: FormInputInstance) => inputField.validate()) && valid
        } else {
          valid = field.validate() && valid
        }
      })
      return valid
    }
  }
}
